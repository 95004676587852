import React from "react"
import arrow from "../../assets/images/right-arrow.png"
import { Link } from 'gatsby';

const Faqs = ({ productRef, product }) => {
    function showAnswer(event) {
        // console.log(event.target.nextSibling.style.display)
        if(event.target.nextSibling.style.display == "" || event.target.nextSibling.style.display == "none")
            event.target.nextSibling.style.display = "block";
        else event.target.nextSibling.style.display = "none";
    }
    return(
        <div className="faqs">
            <div className="auxdiv" id="faqs"></div>
            <div className="auxdiv" id="files"></div>
            <div className="section">
                <h2>FAQs</h2>
            </div>
            <div className="section">
                <h2>FILEs</h2>
            </div>
            <div>
                <div className="faqsContent">
                    { product.faqs.map(function(faq, index) {
                        return(
                            <div style={{"display":"flex"}}>
                                <div className="faq">
                                    <div className="faqsQ" onClick={(event) => showAnswer(event)}>{faq.q} <img onClick={(event) => showAnswer(event)} className="arrow" src={arrow}/></div>
                                    <div className="faqsA">{ faq.a }</div>
                                </div>
                            </div>
                        )
                    }) }
                </div>
            </div>
            <div>
                <div className="brochure">
                    <button className="brochureButton">
                        <Link >BROCHURE</Link>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Faqs
